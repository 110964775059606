var render = function render(){
  var _vm$catalog;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page-full-page', {
    staticClass: "app--main"
  }, [_c('full-page', {
    ref: "fullpage",
    staticClass: "main_fullpage",
    attrs: {
      "options": _vm.options,
      "id": "fullpage"
    }
  }, [_c('div', {
    staticClass: "fullpage-section fullpage-section--visual"
  }, [_c('div', {
    staticClass: "fullpage-inner"
  }, [_c('v-container', [_c('div', {
    staticClass: "visual-ani-group line-height-15 color-white"
  }, [_c('div', {
    staticClass: "visual-ani"
  }, [_c('h2', {
    staticClass: "font-size-38 font-size-md-50 font-size-lg-80 font-weight-bold"
  }, [_vm._v("민아트 EZFLIP™ 프레임")])]), _c('div', {
    staticClass: "visual-ani"
  }, [_c('p', {
    staticClass: "page-text--lg color-grey-f5"
  }, [_vm._v("민아트프레임만의 독보적인 프레임 개폐 기술. 빌트인 스프링 안전 개폐 설계.")])]), _c('div', {
    staticClass: "visual-ani"
  }, [_c('p', {
    staticClass: "page-text--lg color-grey-f5"
  }, [_vm._v("1987년부터 이어져온 고품질 제품 구성을 민아트에서 만나보세요.")])])])])], 1)]), _c('div', {
    staticClass: "fullpage-section fullpage-section--series color-white"
  }, [_c('v-row', {
    staticClass: "h-lg-100",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "px-12 fullpage-section--series__left",
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('div', {
    staticClass: "fullpage-inner justify-lg-end text-lg-right"
  }, [_c('div', {
    staticClass: "pr-lg-50 pr-xl-98",
    attrs: {
      "data-aos": "fade-left"
    }
  }, [_c('p', {
    staticClass: "page-text letter-spacing-6 color-primary line-height-1 mb-20 mb-lg-60"
  }, [_vm._v("PRODUCT FOCUS")]), _c('p', {
    staticClass: "page-text--lg line-height-15 mb-4 mb-lg-8"
  }, [_vm._v("하이라이트 아트 배너 스탠드 시리즈")]), _c('h3', {
    staticClass: "tit font-weight-bold line-height-1 mb-60 mb-lg-140"
  }, [_vm._v("T타입 물통 일체형 배너 스탠드")]), _c('btn-primary', {
    attrs: {
      "to": "/series/product?category=%EC%95%84%ED%8A%B8%20%EB%B0%B0%EB%84%88%20%EC%8A%A4%ED%83%A0%EB%93%9C%20%EF%B9%A0%20%EC%9E%85%EA%B0%84%ED%8C%90",
      "tit": "더보기"
    }
  })], 1)])]), _c('v-col', {
    staticClass: "px-12 fullpage-section--series__right",
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('div', {
    staticClass: "fullpage-inner"
  }, [_c('div', {
    staticClass: "pl-lg-50 pl-xl-98",
    attrs: {
      "data-aos": "fade-right"
    }
  }, [_c('p', {
    staticClass: "page-text letter-spacing-6 color-primary line-height-1 mb-20 mb-lg-60"
  }, [_vm._v("PRODUCT DETAIL")]), _c('p', {
    staticClass: "page-text--lg line-height-15 mb-4 mb-lg-8"
  }, [_vm._v("다양한 색상의 반사지로 눈길을 사로잡는 배너 스탠드 시리즈")]), _c('h3', {
    staticClass: "tit font-weight-bold line-height-1 mb-60 mb-lg-140"
  }, [_vm._v("아트 배너 스탠드 카탈로그")]), _c('btn-primary', {
    attrs: {
      "to": `/res/catalog2/${(_vm$catalog = _vm.catalog2) === null || _vm$catalog === void 0 ? void 0 : _vm$catalog.filename}`,
      "target": "_blank",
      "tit": "더보기"
    }
  })], 1)])])], 1)], 1), _c('div', {
    staticClass: "fullpage-section fullpage-section--partners"
  }, [_c('div', {
    staticClass: "fullpage-inner"
  }, [_c('v-container', [_c('div', {
    staticClass: "tit-wrap pb-40 pb-sm-0 text-center"
  }, [_c('h3', {
    staticClass: "tit line-height-1 font-weight-bold",
    attrs: {
      "data-aos": "fade-up"
    }
  }, [_vm._v("PROJECTS")]), _c('router-link', {
    staticClass: "text-btn",
    attrs: {
      "to": "/business"
    }
  }, [_vm._v("READ MORE +")])], 1), _c('div', {
    staticClass: "partners-slide"
  }, [_c('div', {
    staticClass: "swiper-container"
  }, [_c('div', {
    staticClass: "swiper-wrapper"
  }, _vm._l(_vm.logos, function (logo, idx) {
    return _c('div', {
      key: idx,
      staticClass: "swiper-slide"
    }, [_c('div', {
      staticClass: "partner"
    }, [_c('v-img', {
      staticClass: "w-100",
      attrs: {
        "src": logo.image,
        "contain": ""
      }
    })], 1)]);
  }), 0)]), _c('v-btn', {
    staticClass: "slide-btn slide-prev",
    attrs: {
      "icon": "",
      "outlined": "",
      "color": "grey-7"
    }
  }, [_c('i', {
    staticClass: "icon icon-left-arrow-grey"
  })]), _c('v-btn', {
    staticClass: "slide-btn slide-next",
    attrs: {
      "icon": "",
      "outlined": "",
      "color": "grey-7"
    }
  }, [_c('i', {
    staticClass: "icon icon-right-arrow-grey"
  })])], 1)])], 1)]), _c('div', {
    staticClass: "fullpage-section fullpage-section--inquiry color-white"
  }, [_c('div', {
    staticClass: "fullpage-inner"
  }, [_c('v-container', [_c('div', {
    staticClass: "d-flex flex-column align-center text-center"
  }, [_c('div', {
    attrs: {
      "data-aos": "fade-up"
    }
  }, [_c('v-img', {
    staticClass: "w-100px w-lg-180px mb-20 mb-lg-60",
    attrs: {
      "src": "/images/ci-color.svg",
      "contain": ""
    }
  })], 1), _c('p', {
    staticClass: "page-text--lg line-height-15 mb-60 mb-lg-120"
  }, [_vm._v(" 민아트프레임은 다양한 기성 사이즈 제품군은 물론 특별 주문제작도 가능합니다. "), _c('br'), _vm._v(" 지금 바로 문의하세요. ")]), _c('btn-primary', {
    attrs: {
      "to": "/inquiry",
      "tit": "문의하기"
    }
  })], 1)])], 1)]), _c('div', {
    staticClass: "fullpage-section fullpage-section--footer fp-auto-height"
  }, [_c('main-footer')], 1)]), _c('popup-layer')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }